<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.warehouse.inventoryRecivedListing')" :onSearch="onSearch">                
                <template v-slot:searchBox>                   
                    <CForm>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.agent')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="agents"
                                                     v-model="searchInfo.selectedAgents"
                                                     :loading="isLoadingAgents"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     :internal-search="false"
                                                     @search-change="asyncFindAgency"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.status')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="allDistributeStatus"
                                                     v-model="searchInfo.selectedStatusList"
                                                     :searchable="false"
                                                     :close-on-select="false"
                                                     :clear-on-select="false"
                                                     :limit="10"
                                                     :multiple="true"
                                                     :placeholder="$t('common.picksome')"
                                                     :reset-after="false"
                                                     label="label"
                                                     track-by="key">
                                        </multiselect>                                        
                                    </div>
                                </div>
                            </CCol>
                        </CRow>                       
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="allReceivedInventory"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100] }">
                        <template #footer v-if="allReceivedInventory != null && allReceivedInventory.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="8">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{allReceivedInventory == null ? 0: allReceivedInventory.length}}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #numberOfProducts_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.numberOfProducts)" />
                            </td>
                        </template>
                        <template #amount_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.amount)" />
                            </td>
                        </template>
                        <template #debtPoints_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.debtPoints)" />
                            </td>
                        </template>
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #Distribute_status="{item}">
                            <td class="py-2">                               
                                <button type="button" class="btn" :class="distributeStatusCss(item.status)">{{$t(`pages.orders.orderStatus_${item.status}`)}}</button>
                            </td>
                        </template>
                        <template #distribution_actions="{item, index}">
                            <td class="py-2">
                                <div v-if="item.status <= 1">
                                    <CButton color="primary"
                                             variant="outline"
                                             square
                                             size="sm"
                                             @click="onApproveDistribution(item, index)">
                                        {{ $t('menu.orderPayments') }}
                                    </CButton>                                    
                                    <CButton color="primary" variant="outline" size="sm"
                                         @click="onDeleteWholesaleOrder(item, index)">
                                    {{ $t('common.delete') }}
                                </CButton>
                                </div>
                                <CButton color="primary" variant="outline" square size="sm" @click="onViewOrderDetail(item, index)">
                                    {{ $t('common.detail') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow> 
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import OrderStatus from '@/helpers/OrderStatus'

    export default ({
        name: 'InventoryReceiveList',
        data() {
            return {
                products: [],
                isLoadingProducts: false,
                itemsPerPage: 10,
                agents: [],
                isLoadingAgents: false,
                allDistributeStatus: [],
                fields: [
                    { key: 'id', label: i18n.t('pages.orders.orderNumber') },  
                    { key: 'createdBy', label: i18n.t('pages.orders.buyer') },
                    { key: 'buyingAgentName', label: i18n.t('pages.warehouse.buyingAgent') },
                    { key: 'numberOfProducts_text', label: i18n.t('pages.product.numberOfProducts') },
                    { key: 'amount_text', label: i18n.t('common.amount') },
                    //{ key: 'debtPoints_text', label: i18n.t('common.debtPoints') },
                    { key: 'Distribute_status', label: i18n.t('common.status'), sorter: false, filter: false },
                    { key: 'createdDate_text', label: i18n.t('pages.warehouse.createdDate') },
                    { key: 'distribution_actions', label: i18n.t('common.action'), sorter: false, filter: false },                                  
                ]
            };
        },     
        components: {
            Confirmation,
            ListForm
        },
        computed: {                     
            ...mapState('receivedInventory', ['searchInfo', 'isLoading', 'allReceivedInventory', 'totalPages', 'pageIndex']),
        },
         methods: {        
             ...mapActions('receivedInventory', ['SearchBuyOderOfAgent']),
             
             distributeStatusCss(iStatus) {
                 //if (iStatus == OrderStatus.Pending)
                 //    return "btn-danger";
                 if (iStatus == OrderStatus.Approved)
                     return "btn-primary";
                 if (iStatus == OrderStatus.Shipped)
                     return "btn-info";
                 if (iStatus == OrderStatus.Delivered)
                     return "btn-success";
                 if (iStatus == OrderStatus.Canceled)
                     return "btn-warning";
                 if (iStatus == OrderStatus.Returned)
                     return "btn-dark";

                 //return "btn-danger";
                 return "";
             },           
             formatDate(date) {                 
                 if (date === undefined)
                     return '';
                 return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
             },
             onApproveDistribution(item) {
                 this.$router.push('/warehouses/Approve/' + item.id);               
             },
             onSearch() {
                 this.SearchBuyOderOfAgent();
             },                         

             async onDeleteWholesaleOrder(item) {
                 this.$refs.confirmation.show(i18n.t('pages.warehouse.confirmDeleteTransferInventory'), async () => {
                     var retVal = await this.$store.dispatch("transferInventory/deleteWholesaleOrder", item.id);

                     if (retVal == true) {
                         this.onSearch();
                     }
                 });
             },
                        
             async asyncFindAgency(query, id) {                
                 if (query.length > 3) {
                     this.isLoadingAgents = true;
                     var result = await this.$agentService.searchByName(query);
                     this.isLoadingAgents = false;
                     this.agents = result;
                 }                
             },
             onItemsPerPageChange(item) {
                 localStorage.setItem('sellingAgentOrdersItemsPerPage', item)
             },
             async onViewOrderDetail(item) {
                 this.$router.push('/warehouses/order-detail/' + item.id);
             },
        },       
        created() {
            this.searchInfo.selectedStatusList = [{ key: OrderStatus.Pending, label: i18n.t('common.pending') }];
            this.allDistributeStatus = this.$distributionService.getAllDistributeStatusList();

            this.onSearch();

            let sellingAgentOrdersItemsPerPage = parseInt(localStorage.getItem('sellingAgentOrdersItemsPerPage'));
            if (isNaN(sellingAgentOrdersItemsPerPage))
                sellingAgentOrdersItemsPerPage = 10;
            this.itemsPerPage = sellingAgentOrdersItemsPerPage;
        }
    })
</script>